import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Select } from "components/molecules/Inputs";
import { Badge, Button, NubeskIcons, Switch  } from "components/atoms";
import { SemaphoreProgress } from "components/organism";
import { downloadXls, resetDownnloadXls} from "_redux/product/product.actions";
import selectUiStore from "_redux/ui/ui.selector";
import { selectProductDownloadStore } from "_redux/product/product.selector";
import { useAnalyticProgress, useAppSettings, useChannelLayout, useModal, useProductQuery, useResource } from "_hooks";
import sleep from "_utils/async/sleep";
import { LOADING, SUCCESS } from "_constants/redux.constants";
import { ACTIVE } from "_constants/product.constants";
import { DONE, ELIMINATED, GENERATING_MISSING_LAYOUT, XLSX_TYPE } from "_constants/resource.constants";

const prefix = "o-download-xls-modal";

let interval = undefined;

const DownloadXlsModal = ({ items }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { data: xlsData, status: xlsStatus } = useSelector(selectProductDownloadStore);
  const { selections } = useSelector(selectUiStore);
  
  const { analyzeLayoutCopy, layoutCopyData, layoutCopyReset } = useAnalyticProgress();
  const { appColumnKeyProps } = useAppSettings();
  const { listData, fetch: fetchLayouts, listReset, listStatus } = useChannelLayout();
  const { closeModal } = useModal();
  const { getFromQuery } = useProductQuery();
  const { 
    fetchOne: fetchResource, oneData: resource, oneReset,
    cancelResource, putReset
  } = useResource();
  
  const [autoassign, setAutoassign] = useState(false);
  const [autoregenerate, setAutoregenerate] = useState(false);
  const [dataType, setDataType] = useState();
  const [layout, setLayout] = useState();

  const copyRef = useRef();

  const dataTypeOpts = [{
    label: t("modals:downloadXls.field.dataType.options.status"),
    value: "status"
  }, {
    label: t("modals:downloadXls.field.dataType.options.sku"),
    value: "key"
  }, {
    label: t("modals:downloadXls.field.dataType.options.globalAttributes"),
    value: "globalAttributes",
  }, {
    label: t("modals:downloadXls.field.dataType.options.layoutAttributes"),
    value: "layoutAttributes"
  }];

  const downloadSelection = async () => {
    let endpoint;
    let ids = [];
    let conditions= {};
    let flags = { group: ["@all"] };

    if (items && !selections?.all) {
      ids = items;
    } else {
      if (selections?.all) {
        let { w } = await getFromQuery();
        conditions = w || {status: ACTIVE}; 
      } else {
        ids = Object.keys(selections.selected);
      }
    }
    
    switch (dataType?.value) {
      case "status":
        endpoint = "/folder/download/process";
        flags.filename = `info_general_${ids?.length || ""}`;
      break;
      
      case "key":
        endpoint = "/folder/download/channel";
        flags.filename = `alta_canales_${ids.length || ""}`;
      break;

      case "globalAttributes":
        endpoint = `/folder/download/layout`;
        flags.filename = `global_data_${ids?.length || ""}`;
      break;

      case "layoutAttributes":
        endpoint = `/folder/download/layout/${layout?.value?.key}`;
        flags = {
          ...flags,
          filename: `${layout?.value?.name}_data_${ids?.length || ""}`,
          autoassign,
          autoregenerate
        };
      break;
    }
    
    dispatch(downloadXls(ids, conditions, flags, { 
      endpoint,
      feedback: {
        error: true,
        success: false
      }
    }));
  };

  useEffect(() => {
    fetchLayouts();

    return () => {
      listReset();
      dispatch(resetDownnloadXls());
      oneReset();
      putReset();
      layoutCopyReset();
      if (interval) {
        clearInterval(interval);
        interval = undefined;
      }
    }
  }, []);
  
  useEffect(() => {
    if (xlsData?.type === XLSX_TYPE && !resource) {
      fetchResource({w: {_id: xlsData?._id}});
    }

  }, [xlsData]);

  useEffect(() => {
    if (!interval && GENERATING_MISSING_LAYOUT === resource?.step) {
      interval = setInterval(async() => {
        await fetchResource({w: {_id: xlsData?._id}});
        await analyzeLayoutCopy(xlsData?._id, layout?.value?._id);
      }, [7000]);
    };

    if (resource?.step === DONE && interval) {
      clearInterval(interval);
      interval = undefined;
    }
  }, [resource])

  return (
    <div className={prefix}>
      <div className={`${prefix}__content`}>
        
        {dataType?.value === "layoutAttributes" &&
          <div className={`${prefix}__datamap-selected`}>
            <div className="u-purple">DataMap</div>
            {!!layout && <Badge border="purple" content={layout?.label} />}
          </div>
        }

        {(xlsData?.type === XLSX_TYPE && xlsData?.step !== GENERATING_MISSING_LAYOUT) || (!xlsData?.type && ![SUCCESS, LOADING].includes(xlsStatus)) &&
          <>
            <div>
              <Select 
                emptyLabel={t("modals:downloadXls.field.dataType.placeholder")}
                onChange={setDataType}
                options={dataTypeOpts}
              />
            </div>

            {dataType?.value === "layoutAttributes" &&
              <>
                <div>
                  <Select 
                    disabled={!dataType}
                    emptyLabel={t("modals:downloadXls.field.layout.placeholder")}
                    onChange={setLayout}
                    options={listData?.map((r) => ({
                      label: r?.name,
                      labelInfo: `(${t("modals:downloadXls.field.dataType.options.attributesNum", { n: r?.attributesId?.length ?? 0 })})`,
                      value: {
                        _id: r?._id,
                        name: r?.name,
                        key: r?.key
                      }
                    } ?? []))}
                  />
                </div>

                {dataType && layout &&
                  <div className={`${prefix}_panel-copy-opts`}>
                    <div>
                      <NubeskIcons icon="alert" className="u-purple" size="2rem" />
                      <div>
                        <p>
                          Es posible que algunos {appColumnKeyProps?.name}'s seleccionados no estén asignados al canal.
                        </p>
                        <p>Por default sólo se procesarán los asignados</p>
                        <Switch
                          checked={autoassign}
                          label={"Agregar todos los SKU's seleccionados al canal"}
                          onChange={setAutoassign}
                        />
                      </div>
                    </div>
                    <div>
                      <NubeskIcons icon="alert" className="u-purple" size="2rem" />
                      <div>
                        <p>
                          Es posible que algunos atributos hayan sido procesados previamente.
                        </p>
                        <p>Por default sólo se procesarán los faltantes</p>
                        <Switch
                          checked={autoregenerate}
                          label={"Re-procesar todo el contenido de atributos"}
                          onChange={setAutoregenerate}
                        />
                      </div>
                    </div>
                  </div>
                }
              </>
            }
          </>
        }

        {(resource?.step === GENERATING_MISSING_LAYOUT || (!xlsData?.url && xlsStatus === LOADING)) && 
          <SemaphoreProgress
            label={"Procesando atributos"}
            completed={dataType?.value === "layoutAttributes" ? layoutCopyData?.[0]?.withLayoutData ?? 0 : items.length - 1}
            total={items.length}
          />
        }

        {(resource?.step === DONE || (xlsData?.url && xlsStatus === SUCCESS)) &&
          <div className={`${prefix}__output`}>
            <div onClick={() => window.open(
                dataType?.value === "layoutAttributes" ? resource?.data?.url : xlsData?.url
              )} className="">
              <NubeskIcons
                className="u-purple"
                icon="excel"
                size="5rem"
              />
              <Button 
                content="Descargar XLS"
                type="underline"
              />
            </div>
      
            <div onClick={async () => {
              await sleep(100).then(() => { });
              copyRef.current.select();
              document.execCommand("copy");
            }}>
              <input
                type="text"
                ref={copyRef}
                value={dataType?.value === "layoutAttributes" ? resource?.data?.url : xlsData?.url}
              />
              <NubeskIcons icon="linkInclined" size="5rem" className="u-purple"/>
              <Button
                content="Copiar Link"
                type="underline"
              />
            </div>
          </div>
        }
      </div>

      {((dataType?.value === "layoutAttributes" && resource?.step === GENERATING_MISSING_LAYOUT) || (![SUCCESS, LOADING].includes(xlsStatus))) &&
        <div className={`${prefix}__buttons`}>
          <Button
            content={t("common:form.cancel")}
            onClick={() => {
              if (dataType?.value === "layoutAttributes" && !!resource) {
                cancelResource(resource?._id);
              }
              closeModal();
            }}
            type="underline"
          />
          
          {!!resource && dataType?.value === "layoutAttributes" ?
            <Button
              content="Cerrar y notificar al finalizar"
              disabled={!resource}
              onClick={closeModal}
              type="purple"
              sm
            />
          :
            <Button
              disabled={xlsStatus === LOADING || listStatus === LOADING || !dataType || (dataType?.value === "layoutAttributes" && !layout)}
              content={t("common:words.download")}
              type="purple"
              onClick={downloadSelection}
              sm
            />
          }
        </div>
      }

    </div>
  );
};

export default DownloadXlsModal;
