export const reduxActions = {
  UPDATE_GTIN_FAIL: "syncfonia/update_gtin_fail",
  UPDATE_GTIN_RESET: "syncfonia/update_gtin_reset",
  UPDATE_GTIN_STARTED: "syncfonia/update_gtin_started",
  UPDATE_GTIN_SUCCESS: "syncfonia/update_gtin_success",

  UPDATE_GTIN_PROVIDER_FAIL: "syncfonia/update_gtin_provider_fail",
  UPDATE_GTIN_PROVIDER_RESET: "syncfonia/update_gtin_provider_reset",
  UPDATE_GTIN_PROVIDER_STARTED: "syncfonia/update_gtin_provider_started",
  UPDATE_GTIN_PROVIDER_SUCCESS: "syncfonia/update_gtin_provider_success",

  UPDATE_CATALOG_PROVIDER_FAIL: "syncfonia/update_catalog_provider_fail",
  UPDATE_CATALOG_PROVIDER_RESET: "syncfonia/update_catalog_provider_reset",
  UPDATE_CATALOG_PROVIDER_STARTED: "syncfonia/update_catalog_provider_started",
  UPDATE_CATALOG_PROVIDER_SUCCESS: "syncfonia/update_catalog_provider_success",

  UPDATE_CATALOG_RETAIL_FAIL: "syncfonia/update_catalog_provider_fail",
  UPDATE_CATALOG_RETAIL_RESET: "syncfonia/update_catalog_provider_reset",
  UPDATE_CATALOG_RETAIL_STARTED: "syncfonia/update_catalog_provider_started",
  UPDATE_CATALOG_RETAIL_SUCCESS: "syncfonia/update_catalog_provider_success"
};

export const $SYNCFONIA_RETAIL_GTIN_DATA = "gs1retailer:updategtindata";
export const $SYNCFONIA_RETAIL_GTIN_ASSETS = "gs1retailer:updategtinassets";
export const $SYNCFONIA_RETAIL_GTIN_CATALOG = "gs1retailer:updategtincatalogue";

export const $SYNCFONIA_PROVIDER_GTIN_DATA = "gs1provider:updategtindata";
export const $SYNCFONIA_PROVIDER_GTIN_ASSETS = "gs1provider:updategtinassets";
export const $SYNCFONIA_PROVIDER_CATALOG = "gs1provider:updategtincatalogue";
