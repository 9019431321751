import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { AppRoutes } from "_config/routes";
import { useAppSettings, useLocalStorage, useMediaHelp, useScreen } from "_hooks";
import { NubeskLoader } from "components/molecules"
import { isLoggedIn } from "_redux/user/user.actions";
import { selectAppConfigGot } from "_redux/app/app.selector";
import { selectUser, selectUserStatus } from "_redux/user/user.selectors";
import { closeLoader } from "_redux/ui/ui.actions";
import { getCookie, setCookie } from "_utils/cookie";
import { LOADING, IDLE, FAILURE, SUCCESS } from "_constants/redux.constants";

let timer;
let checkTimer;
let second = 1000;
let minute = 60 * second;
let hour = 60 * minute;
let duration = parseInt(process.env.REACT_APP_SESSION_EXPIRES || 8 * hour);
let cookieName = "Nubesk_v2.0_session";

const Routes = () => {
  const dispatch = useDispatch();

  const initialized = useRef(null);

  const userStatus = useSelector(selectUserStatus);
  const user = useSelector(selectUser);
  const appConfigGot = useSelector(selectAppConfigGot);

  const { fetchAllSettings } = useAppSettings();
  const [getLS, setLs] = useLocalStorage();
  const { launchVideo } = useMediaHelp();
  const { getSizes } = useScreen();

  useEffect(() => {
    if (!initialized?.current) {
      initialized.current = true;
      let clientLs = getLS("client");
      if (!clientLs?.screen?.validate) {
        let screen = getSizes();
        
        setLs("client", {
          ...clientLs ?? {},
          screen: {
            validate: true,
            ...screen
          }
        });
        if (screen?.width < 1024) {
          window.location = "/screenNotCompatible";
        }
      }

      if (userStatus === IDLE) {
        dispatch(isLoggedIn());
      }
    }
    
    return () => {
      if (checkTimer) clearTimeout(checkTimer);
    }
  }, []);

  useEffect(() => {
    if (userStatus === FAILURE) {
      timer = setTimeout(() => dispatch(closeLoader()), 1000);
    }
    return () => {
      if (timer) clearTimeout(timer);
    }
  }, [userStatus]);


  useEffect(() => {
    if (!!user?.__freeTrial) {
      let expired = false;
      let { startDate, ttl } = user?.__freeTrial;
      let now = moment();
      let end = moment(startDate).add("days", ttl);

      if (end < now) expired = true;

      if (user?.__analytics?.backremovals) {
        let { billingPlan, byAccount } = user.__analytics.backremovals;        
        if (billingPlan?.limit <= byAccount?.count) {
          expired = true;
        }
      }

      if (expired) {
        window.location.replace(process.env.REACT_APP_EXPIRED_TRIAL);
        return;
      }
    }

    if (user) fetchAllSettings();
    
  }, [user]);

  useEffect(() => {
    if (!user || !appConfigGot) return; 
    launchVideo();
  }, [user, appConfigGot]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (user && userStatus === SUCCESS) {
      let now = Date.now();
      let expires = getCookie(cookieName);
      let rest = expires - now;
      if (!(expires && now < expires)) {
        setCookie(cookieName, Date.now() + duration, duration);
      }

      checkTimer = setTimeout(() => {
        dispatch(isLoggedIn());
      }, rest);
    } else if (userStatus === FAILURE) {
      window.location.replace(`${process.env.REACT_APP_CORE_WEB}/account/login`);
    }
  }, [userStatus]);

  return (
    <>
      <NubeskLoader 
        visible={[LOADING, IDLE].includes(userStatus) || user === null || !appConfigGot}
      />
      {[LOADING, IDLE].includes(userStatus) || user === null || !appConfigGot ?
        null
      : 
        <AppRoutes />
      }

    </>
  );
};

export default Routes;
