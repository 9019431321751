import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { FlexComponent, FormControl } from "components/templates";
import { SimpleScroller} from "components/organism";
import { IconDrawer, Toast  } from "components/molecules";
import { Select } from "components/molecules/Inputs";
import { Badge, Button, Input, NubeskIcons, Switch } from "components/atoms";
import ApproveData from "./ApproveData";
import { useAttribute, useDetailsPhoto, useFile, useLayer, useModal, useProduct } from "_hooks";
import { LOADING } from "_constants/redux.constants";
import { AI, AI_INTEGRATED, HERO, ISOMETRIC, SHOW_TYPES } from "_constants/layer.constants";

const prefix = "o-generate-layer-images-modal";

const SELECTION = 1;
const FETCH = 2;
const ANALYSIS = 3;
const PREVIEW = 4;
const AI_GENERATE = 5;

const LOADING_LABEL = {
  [ANALYSIS]: "Analizando imagen...",
  [FETCH]: "Recuperando atributos...",
  [PREVIEW]: "Generando vista previa...",
  [AI_GENERATE]: "Generando imágenes..."
};

let abortController;

const GenerateLayerImagesModal =  ({ images, productId, sku }) => {
  const { toLayerReset, toLayerStatus } = useAttribute();
  const { t } = useTranslation();
  const { aiBgGenerate, aiBgGenerateReset, aiBgGenerateStatus } = useFile();
  const { 
    aiImageTypeOpts,
    aiImageStyleOpts,
    fetchList, 
    previewData: previewLayerData,
    previewStatus: previewLayerStatus,
    previewLayer,
    previewReset: previewLayerReset,
    listData: listLayer,
    listReset: listLayerReset
  } = useLayer();
  const { closeModal } = useModal();
  const {
    aiAnalysisProduct,
    aiAnalysisData,
    aiAnalysisReset,
    aiAnalysisStatus,
    canvasEditor,
    openCanvasEditor,
    setLayerData,
    updateProduct
  } = useProduct();

  const [filesId, setFilesId] = useState([images?.[0]?._id]);
  const [aiData, setAiData] = useState();
  const [step, setStep] = useState(SELECTION);
  const { setAdaptationSelected } = useDetailsPhoto();
  const [selectedLayer, setSelectedLayer] = useState();

  const stepChange = () => {
    switch (step) {
      case SELECTION:
        if ([HERO, ISOMETRIC].includes(selectedLayer?.type)) {
          return setStep(FETCH);
        }
        if (selectedLayer?.type === AI) {
          return setStep(ANALYSIS);
        }
      case ANALYSIS:
        return setStep(AI_GENERATE);
    }
  };
  
  const stepHandler = async () => {
    switch (step) {
      case FETCH:
        if (!selectedLayer?.builder?.attributes?.length) {
          setStep(PREVIEW);
        }
        break;

      case ANALYSIS:
        let _config = {
          folderId: productId,
          fileId: filesId[0],
          adaptationLayerId: selectedLayer?.value,
          data: {
            mask: aiData.imageType.value === AI_INTEGRATED,
            style: aiData.imageStyle.value,
            keywords: aiData?.keywords?.length ? aiData.keywords : undefined
          },
          $config: {
            targetLength: 4,
          }
        };

        if (aiData?.reprocess) _config.$config.overwrite = { data: true };

        aiAnalysisProduct(_config, {
          feedback: { error: true, success: false },
          signal: abortController.signal
        }).then((response) => {
          if (response?.data) {
            stepChange();
          }
        }).catch(() => {});
        break;

      case PREVIEW:
        previewLayer({
          folderId: productId,
          filesId,
          adaptationLayerId: selectedLayer?.value
        }, { signal: abortController.signal });
        break;

      case AI_GENERATE:
        let promises = [];
        let modifiers = {
          mask: aiData.imageType.value === AI_INTEGRATED,
          style: aiData.imageStyle.value,
          keywords: aiData?.keywords?.length ? aiData.keywords : undefined,
        };

        let config = {
          folderId: productId,
          fileId: filesId[0],
          adaptationLayerId: selectedLayer?.value,
          data: modifiers
        };
        
        let prompts = aiAnalysisData?.data?.generated_image_data?.map(
          (g) => `${g.sdxl_prompt?.[0]?.text} | ${g.sdxl_prompt?.[1]?.text}`
        );
        
        let previewReq = await previewLayer({
          folderId: productId,
          filesId,
          adaptationLayerId: selectedLayer?.value
        }, {
          signal: abortController.signal
        }).catch(() => {
          toast.error(<Toast info={t("common:words.error")} area={t("common:words.error")} />);
          return;
        });

        for (let i = 0; i < prompts.length; i++) {
          promises.push(aiBgGenerate({
            ...config,
            $config: {
              imageLength: 1,
              prompt: prompts[i]
            }
          }, { 
            feedback: { error: true, success: true },
            signal: abortController.signal
          }));
        }
        
        Promise.all(promises).then((responses) => {
          if (responses?.[0] === undefined || responses?.[1] === undefined) return;
          let bgImages = [];
          let conf;

          for (let i = 0; i < responses.length; i++) {
            if (responses?.[i]?.data?.[0]?.generated?.[0]) {
              bgImages.push(responses?.[i].data[0].generated[0]);
            }
          }
          
          conf = {
            bgImages,
            layer: selectedLayer,
            onAfterSave: (data) => {
              if (data?.adaptationLayerId) {
                setAdaptationSelected(data.adaptationLayerId);
              }
            },
            previews: previewReq?.data,
            prompts,
            sku
          };

          conf.modifiers = modifiers;
    
          setLayerData(conf);
        });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    abortController = new AbortController();
    fetchList();
    
    return () => {
      abortController.abort();
      previewLayerReset();
      listLayerReset();
      aiAnalysisReset();
      aiBgGenerateReset();
      toLayerReset();
    }
  }, []);

  useEffect(() => {
    if (selectedLayer?.type === AI) setAiData({
      imageType: aiImageTypeOpts[selectedLayer?.data?.mask === true ? 1 : 0],
      imageStyle: aiImageStyleOpts?.filter((o) => o.value === selectedLayer?.data?.style)?.pop(),
      keywords: selectedLayer?.data?.keywords ?? "",
      reprocess: true
    })
    else if (aiData) setAiData();
  }, [selectedLayer?._id]);

  useEffect(() => {
    if (previewLayerData && selectedLayer?.type !== AI) {
      setLayerData({
        layer: selectedLayer,
        onAfterSave: (data) => {
          if (data?.adaptationLayerId) {
            setAdaptationSelected(data.adaptationLayerId);
          }
        },
        previews: previewLayerData,
        sku
      });
    }
  }, [previewLayerData]);
  
  useEffect(() => {
    if ((canvasEditor?.data && !canvasEditor?.visible)) {
      closeModal();
      openCanvasEditor();
    }
  }, [canvasEditor]);

  useEffect(() => {
    stepHandler();
  }, [step]);

  return (
    <div className={prefix}>
      {(previewLayerStatus === LOADING || toLayerStatus === LOADING ||
      aiAnalysisStatus === LOADING || (selectedLayer?.type === AI && aiBgGenerateStatus === LOADING)) &&
        <div className={`${prefix}__processing`}>
          <IconDrawer
            icon={(function() {
              switch (step) {
                case FETCH:
                case ANALYSIS:
                  return "search";
                case PREVIEW:
                case AI_GENERATE:
                  return "pencil";
              }
            })()}
            iconProps={{style: {fontSize: "3rem"}}}
          />
          <div className={`${prefix}__processing-data`}>{LOADING_LABEL[step]}</div>
        </div>
      }
      
      {step === SELECTION && <>
        <div className={`${prefix}__carousell`}>
          <SimpleScroller isHorizontal>
            <FlexComponent>
              {images?.map((img, index) => (
                <div 
                  className={`--thumbnail ${filesId.includes(img._id) ? "--selected" : ""}`}
                  key={index}
                  onClick={() => setFilesId([img._id])}
                  style={{backgroundImage: `url("${img.baseKeyS3Carousel}`}}
                ></div>
              ))}
            </FlexComponent>
          </SimpleScroller>
        </div>

        <div className={`${prefix}__selector`}>
          <Select 
            emptyLabel="Seleccionar la plantilla"
            onChange={setSelectedLayer}
            options={listLayer?.filter((l) => l?.template?.portrait || l?.template?.landscape|| l?.template?.square).map((row) => ({
              label: <div className="--list-item-opt">
                <Badge border="purple" content={SHOW_TYPES[row.type]} />
                {row?.name}
              </div>,
              value: row?._id,
              ...row,
            })) ?? [] }
            value={selectedLayer}
          />
        </div>
        
        {selectedLayer?.type && [HERO, ISOMETRIC].includes(selectedLayer.type) &&
          <>
            <div className={`${prefix}__preview`}>
              {selectedLayer?.template?.portrait && <div style={{backgroundImage: `url(${selectedLayer?.thumbnail?.portrait?.src})`}}></div>}
              {selectedLayer?.template?.landscape && <div style={{backgroundImage: `url(${selectedLayer?.thumbnail?.landscape?.src})`}}></div>}
              {selectedLayer?.template?.square && <div style={{backgroundImage: `url(${selectedLayer?.thumbnail?.square?.src})`}}></div>}
            </div>

            <div className={`${prefix}__legend`}>
              <NubeskIcons icon="alert" size="2.5rem" />
              La orientación se selecionará de forma automática según la imagen seleccionada del producto.
            </div>
          </>
        }

        {selectedLayer?.type && selectedLayer.type === AI && 
          <div className={`${prefix}__ai-config`}>
            <div className="--preview">
              {selectedLayer?.template?.portrait && <div style={{backgroundImage: `url(${selectedLayer?.thumbnail?.portrait?.src})`}}></div>}
              {selectedLayer?.template?.landscape && <div style={{backgroundImage: `url(${selectedLayer?.thumbnail?.landscape?.src})`}}></div>}
              {selectedLayer?.template?.square && <div style={{backgroundImage: `url(${selectedLayer?.thumbnail?.square?.src})`}}></div>}
            </div>
            <div>
              {aiData?.imageType !== undefined &&
                <FormControl
                  content={<Select
                    fullwidth
                    onChange={(value) => setAiData((d) => ({
                      ...d,
                      imageType: value,
                      reprocess: true
                    }))}
                    options={aiImageTypeOpts}
                    value={aiData.imageType}
                    xs
                  />}
                  labelProps={{content: "Tipo de imagen", size:"sm"}}
                  orientation="vertical"
                />
              }

              {aiData?.imageStyle !== undefined &&
                <FormControl
                  content={<Select
                    fullwidth
                    onChange={(value) => setAiData((d) => ({
                      ...d,
                      imageStyle: value,
                      reprocess: true
                    }))}
                    options={aiImageStyleOpts}
                    value={aiData.imageStyle}
                    xs
                  />}
                  labelProps={{content: "Estilo de imagen", size:"sm"}}
                  orientation="vertical"
                />
              }

              {aiData?.keywords !== undefined && 
                <FormControl
                  content={<Input
                    onChange={({ target: { value }}) => 
                      setAiData((d) => ({
                      ...d,
                      keywords: value,
                      reprocess: true
                    }))}
                    placeholder="pe. Christmas, Halloween"
                    size="sm"
                    value={aiData?.keywords}
                  />}
                  labelProps={{content: "Palabras clave", size:"sm"}}
                  orientation="vertical"
                />
              }

              <FormControl
                content={<Switch 
                  label="Reprocesar"
                  checked={aiData?.reprocess}
                  onChange={() => setAiData((d) => ({
                    ...d,
                    reprocess: !d.reprocess
                  }))}
                />}
                labelProps={{content: " ", size:"sm"}}
                orientation="vertical"
              />
            </div>
          </div>  
        }

      </>}

      {selectedLayer?.type && [HERO, ISOMETRIC].includes(selectedLayer.type) && step === FETCH && 
        <ApproveData
          attributes={selectedLayer?.builder?.attributes}
          file={images?.filter((i) => i._id == filesId)?.pop() ?? {}}
          productId={productId}
          prefix={prefix}
          onCancel={closeModal}
          onSave={async (newData) => {
            if (newData && Object.keys(newData)?.length) {
              await updateProduct(productId, (function() {
                let output = {};
                Object.keys(newData).forEach(k => {
                  output[`data.${k}`] = newData[k]
                });
                return output;
              })());
            }
            setStep(PREVIEW);
          }}
        />
      }

      {[SELECTION, ANALYSIS, PREVIEW, AI_GENERATE].includes(step) &&
        <div className={`${prefix}__buttons`}>
          <Button
            content={t("common:form.cancel")}
            disabled={previewLayerStatus === LOADING}
            onClick={() => closeModal()}
            type="underline"
          />
          <Button
            content={(function() {
              switch (step) {
                case FETCH:
                  return "Guardar y generar";
                default:
                  return "Generar";
              }
            })()}
            disabled={
              !selectedLayer || previewLayerStatus === LOADING || toLayerStatus === LOADING ||
              aiAnalysisStatus === LOADING || 
              (selectedLayer?.type === AI && aiBgGenerateStatus === LOADING)
            }
            onClick={stepChange}
            sm
            type="purple"
          />
        </div>
      }
    </div>
  );
};

GenerateLayerImagesModal.propTypes = {
  images: PropTypes.array.isRequired,
  productId: PropTypes.string.isRequired,
  sku: PropTypes.string
};

export default GenerateLayerImagesModal;
