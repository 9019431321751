import { useTranslation } from "react-i18next";
import { Input } from "components/atoms";
import { FormInput } from "components/molecules/Form";
import { Accordion } from "components/organism";
import { FormControl } from "components/templates";
import { useNbskEditor } from "_hooks";
import { SIZE_POS_BORDERR1, SIZE_POS_BORDERR2, SIZE_POS_HEIGHT, SIZE_POS_POSX, SIZE_POS_POSY, SIZE_POS_ROTATE, SIZE_POS_WIDTH } from "_constants/nbskEditor.constants";

const SizePosSection = ({ canvas, selection, onChange, prefix, values }) => {
  const { t } = useTranslation();

  const { isVisible } = useNbskEditor(canvas);

  return(
    <Accordion
      title={t("nbsk:editor.shapes.sizeSection")}
      headerRight={null}
      isOpen={true}
      content={
      <div
        className={`${prefix}__size-pos`}
      >
        {isVisible(selection, SIZE_POS_WIDTH) &&
          <FormInput
            className="--width"
            label="Width (ancho)"
            name={SIZE_POS_WIDTH}
            onChange={({target: { name, value }}) => onChange({ name, value })}
            orientation="vertical"
            size="sm"
            value={values?.[SIZE_POS_WIDTH]}
          />
        }
        
        {isVisible(selection, SIZE_POS_HEIGHT) &&
          <FormInput
            className="--height"
            label="Height (alto)"
            name={SIZE_POS_HEIGHT}
            onChange={({target: { name, value }}) => onChange({ name, value })}
            orientation="vertical"
            size="sm"
            value={values?.[SIZE_POS_HEIGHT]}
          />
        }

        {isVisible(selection, SIZE_POS_POSX) &&
          <FormInput
            className="--pos"
            label="Pos X"
            name={SIZE_POS_POSX}
            onChange={({target: { name, value }}) => onChange({ name, value })}
            orientation="vertical"
            size="sm"
            value={values?.[SIZE_POS_POSX]}
          />
        }

        {isVisible(selection, SIZE_POS_POSY) &&
          <FormInput
            className="--pos"
            label="Pos Y"
            name={SIZE_POS_POSY}
            onChange={({target: { name, value }}) => onChange({ name, value })}
            orientation="vertical"
            size="sm"
            value={values?.[SIZE_POS_POSY]}
          />
        }

        {isVisible(selection, SIZE_POS_ROTATE) &&
          <FormInput
            className="--rotate"
            label="Rotación"
            name={SIZE_POS_ROTATE}
            onChange={({target: { name, value }}) => onChange({ name, value })}
            orientation="vertical"
            size="sm"
            value={values?.[SIZE_POS_ROTATE]}
          />
        }

        {isVisible(selection, [SIZE_POS_BORDERR1, SIZE_POS_BORDERR2]) &&
          <FormControl
            className="--border-radius"
            content={
              <>
                <Input
                  name={SIZE_POS_BORDERR1}
                  onChange={({target: { name, value }}) => onChange({ name, value })}
                  size="sm"
                  value={values?.[SIZE_POS_BORDERR1]}
                />
                <Input
                  name={SIZE_POS_BORDERR2}
                  onChange={({target: { name, value }}) => onChange({ name, value })}
                  size="sm"
                  value={values?.[SIZE_POS_BORDERR2]}
                />
              </>
            }
            labelProps={{
              content: "Bordes redondeados",
              size: "sm"
            }}
            orientation="vertical"
          />
        }
      </div>
      }
    />
  );
};

export default SizePosSection;
