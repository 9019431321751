import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { NubeskIcons } from "components/atoms/index";
import CountUp from "react-countup";
import { selectProductStore } from "_redux/product/product.selector";
import { selectUser } from "_redux/user/user.selectors";
import { $$FOLDER } from "_constants/permission.constants";

const prefix = "o-complete-upload";

const CompleteUpload = ({ file, selection, setSelection }) => {
  const { t } = useTranslation();
  const { post: postData } = useSelector(selectProductStore);
  const [count, setCount] = useState({});

  const user = useSelector(selectUser);

  useEffect(() => {
    setCount(postData.data.countRow);
    return () => setCount({});
  }, []);

  const handleChange = ({ target: { name, checked } }) => {
    setSelection({ ...selection, [name]: checked });
  };

  return (
    <div className={prefix}>
      <div className={`${prefix}__header`}>
        <span className={`${prefix}__file`}>{file.path}</span>
        <a
          style={{ maxHeight: 20, minheight: 5 }}
          className={"a-button a-button--underline"}
          href={
            postData?.data?.file?.url
          }
          download
        >
          <NubeskIcons icon="download" size={19} />
          <u>{t("products:newSku.details")}</u>
        </a>
      </div>
      <div className={`${prefix}__content`}>
        <div className={`${prefix}__content-item header`}>
          <span className={`${prefix}__content-text`}>
            {t("products:newSku.select_changes")}
          </span>
          <div></div>
          <span> {t("products:newSku.select")}</span>
        </div>
        {user.canDoIt([$$FOLDER.CREATE]) ?
          <div className={`${prefix}__content-item`}>
            <CountUp
              end={count.toCreate}
              className={`${prefix}__content-qty`}
              duration={1}
            />
            <span className={`${prefix}__content-text`}>
              new SKU {t("products:newSku.created")}
            </span>
            {count.toCreate && user.canDoIt([$$FOLDER.CREATE]) ? (
              <input type="checkbox" name="create" onChange={handleChange} />
            ) : null}
          </div>
          : null
        }

        <div className={`${prefix}__content-item`}>
          <CountUp
            className={`${prefix}__content-qty`}
            end={count.toUpdate}
            duration={1}
          />
          <span className={`${prefix}__content-text`}>
            SKU {t("products:newSku.modified")}
          </span>
          {count.toUpdate ? (
            <input type="checkbox" name="update" onChange={handleChange} />
          ) : null}
        </div>
        {count.withError !== 0 ?
          <div className={`${prefix}__content-item ${prefix}__content-item--red`}>
            <CountUp
              className={`${prefix}__content-qty`}
              end={count.withError}
              duration={1}
            />
            <span className={`${prefix}__content-text`}>
              {t("products:newSku.missing")}
            </span>
          </div>
          : null}


      </div>
    </div>
  );
};

export default CompleteUpload;
