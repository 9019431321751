import { reduxActions as APP } from "_constants/app.constants";
import { FAILURE, LOADING, SUCCESS } from "_constants/redux.constants";

const getLookAndFeel = {
  [APP.LOOK_AND_FEEL_STARTED]: (state) => ({
    ...state,
    lookAndFeel: {
      ...state.lookAndFeel,
      status: LOADING
    }
  }),

  [APP.LOOK_AND_FEEL_FAIL]: (state, { payload }) => ({
    ...state,
    lookAndFeel: {  
      ...state.lookAndFeel,
      error: payload?.error ?? null,
      status: FAILURE
    }
  }),

  [APP.LOOK_AND_FEEL_SUCCESS]: (state, { payload }) => ({
    ...state,
    lookAndFeel: {
      ...state.lookAndFeel,
      data: payload?.data?.[0]?.data ?? null,
      status: SUCCESS
    }
  })
};

export default getLookAndFeel;
