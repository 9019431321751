import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { filterAnalizeId } from "_redux/product/product.actions";

const prefix = "m-filter-group__filter-area";

const FilterArea = ({ onChange, ...props }) => {
  const dispatch = useDispatch();

  const [value, setValue] = useState();

  const ref = useRef();

  useEffect(() => {
    if (ref.current) ref.current.focus();
  }, []);
  const changeHandler = (values) => {
    if (!values) return onChange(undefined);

    let regexp = /[\s|\n|,]/;
    values = values.replace(regexp, "\n");
    let split = values.split("\n");
    let clean = split.filter((value) => {
      value = value.trim();
      if (value.length) {
        return true;
      }
      return false;
    });

    fetch(clean);
  };

  const fetch = async (array) => {
    let response = await dispatch(
      filterAnalizeId({
        f: ["key"],
        w: {
          key: array,
        },
      })
    );

    if (response?.data) {
      let { data } = response;
      let notFound = [];
      data = data.map((row) => row.key);

      if (array.length !== data.length) {
        notFound = array.filter((row) => data.indexOf(row) === -1);
      }

      onChange({
        found: data,
        notFound,
      });
    }
  };

  useEffect(() => {
    changeHandler(value);
  }, [value]);

  return (
    <div className={prefix}>
      <textarea
        ref={ref}
        // onDoubleClick={() => {}}
        minRows={6}
        maxRows={6}
        value={value}
       style={{height: "100%"}}
        onChange={(e) => setValue(e.target.value)}
        {...props}
      />
    </div>
  );
};

export default FilterArea;
