import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Intercom, { show } from "@intercom/messenger-js-sdk";
import ClassNames from "classnames";
import { useAppSettings, useUser } from "_hooks";
import { FlexComponent } from "components/templates";
import { Button, NubeskIcons } from "components/atoms";
import { logout } from "_redux/user/user.actions";
import { selectLoader } from "_redux/ui/ui.selector";
import { closeLoader, closePanelLeft, toggleRefresh } from "_redux/ui/ui.actions";
import { sleep } from "_utils/async";
import version from "version";

const prefix = "o-sidebar";

const Sidebar = ({ menuFixed, setMenuFixed }) => {
  const { appMediaHelp } = useAppSettings();
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const { user } = useUser();

  const [blockHover, setBlockHover] = useState(false);
  const { isOpen } = useSelector(selectLoader);
  const classForComponent = ClassNames(prefix, {
    "is-open": menuFixed,
    "block-hover": blockHover,
  });


  const changeRoute = async (route) => {
    await dispatch(closeLoader());
    history.push(route);
  };

  const logoutHandler = () => {
    let urlRedirect = process.env.REACT_APP_CORE_WEB;
    if (!user?.fork || user?.fork === "core") {
      urlRedirect += "/account/login";
    } else {
      urlRedirect += `/partner/${user?.fork}/login?callback=logout`;
    }
    dispatch(closeLoader());
    dispatch(logout()).then(() => {
      window.location.replace(urlRedirect);
    });
  };

  const initChat = useCallback(() => {
    Intercom({ app_id: "fptaenb9" });
  }, []);

  useEffect(() => {
    initChat();
  }, [initChat]);
  
  useEffect(() => {
    if (isOpen) return setBlockHover(true);
    let timer = setTimeout(() => {
      setBlockHover(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, [isOpen]);

  return (
    <div className={classForComponent} >
      <Button
        className={`${prefix}__pin`}
        content={<NubeskIcons icon="outlinePin" />}
        type={"primary"}
        isActive={menuFixed}
        onClick={() => setMenuFixed((previous) => !previous)}
      />
      <FlexComponent isVertical isSidebar>
        <Button
          content={t("sidebar:menu.products")}
          leftIcon={<NubeskIcons icon="products2" />}
          type="color"
          isActive={pathname.includes("products")}
          onClick={async () => {
            changeRoute("/products", "");
            dispatch(closePanelLeft());
            sleep(100).then(() => dispatch(toggleRefresh("products")));
          }}
        />
        <Button
          content={t("sidebar:menu.tasks")}
          leftIcon={<NubeskIcons icon="tasks2" />}
          isActive={pathname.includes("tasks")}
          type="color"
          onClick={() => changeRoute("/tasks")}
        />
      </FlexComponent>
      
      <div className={`${prefix}__config`}>
        <div className={`${prefix}__powered-by`}
          onClick={() => window.open("https://nubesk.com.mx")}
        >
          <div className="--legend">
            <p>Powered by Nubesk</p>
          </div>
          <div className="--full"></div>
          <div className="--version"><p>{version}</p></div>
        </div>
        <FlexComponent isVertical isSidebar>          
          <Button
            content={t("sidebar:menu.help")}
            leftIcon={<NubeskIcons icon="help" />}
            type="color"
            onClick={() => window.open(
              (!user?.fork ? appMediaHelp?.core?.helpCenter?.url : appMediaHelp?.[user.fork]?.helpCenter?.url) || "", "_blank"
            )}
          />

          <Button
            content={t("sidebar:menu.chat")}
            leftIcon={<NubeskIcons icon="chat" />}
            type="color"
            id="chat"
            onClick={() => show()}
          />

          <Button
            content={t("sidebar:menu.settings")}
            leftIcon={<NubeskIcons icon="settings" />}
            type="color"
            onClick={() => {
              changeRoute("/settings/sales-channels");
            }}
          />
        </FlexComponent>
        <Button
          className={`${prefix}__logout`}
          content={t("sidebar:menu.logout")}
          type="color"
          leftIcon={<NubeskIcons icon="logout" />}
          onClick={logoutHandler}
        />
      </div>
    </div>
  );
};

export default Sidebar;
