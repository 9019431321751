import React, { useState, useLayoutEffect, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { TableCatalogs } from "components/molecules";
import { Button, NubeskIcons } from "components/atoms";
import { FlexComponent } from "components/templates";
import { resetOne } from "_redux/reader/reader.actions";
import { selectCatalogAllStore } from "_redux/catalog/catalog.selector";
import { ARCHIVED, OWNER, SHARED } from "_constants/catalog.constants";
import { changeToolbar, resetSelection } from "_redux/ui/ui.actions";
import { selectSkuSelected } from "_redux/ui/ui.selector";

const prefix = "o-catalogs-list";

const CatalogsList = ({ onSetCatalog }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const selections  = useSelector(selectSkuSelected);

  const { data: catalogList } = useSelector(selectCatalogAllStore);

  const [type, setType] = useState(OWNER);
  useEffect(()=>{
    return () => dispatch(resetSelection())
  },[])
  useLayoutEffect(() => {
    dispatch(changeToolbar("products"));
    return () => dispatch(resetOne());
  }, []);

  return (
    <div className={prefix}>
      <div className={`${prefix}__navigation`}>
        <FlexComponent sm>
          <Button
            leftIcon={<NubeskIcons icon="user" size={27} />}
            onClick={() => setType(OWNER)}
            isActive={type === OWNER}
            type="tabs"
          />
          <Button
            leftIcon={<NubeskIcons icon="share" size={27} />}
            onClick={() => setType(SHARED)}
            type="tabs"
            isActive={type === SHARED}
          />
          <Button
            leftIcon={<NubeskIcons icon="archive" size={27} />}
            onClick={() => setType(ARCHIVED)}
            type="tabs"
            isActive={type === ARCHIVED}
          />
        </FlexComponent>
        
      </div>
      <div className={`${prefix}__table`}>
        <TableCatalogs
          area={t(`catalogs:general.navigation.${type}`)}
          onSetCatalog={onSetCatalog}
          catalogList={catalogList}
          withSelection={selections.all || Object.keys(selections.selected).length}
          type={type}
        />
      </div>
    </div>
  );
};

CatalogsList.propTypes = {
  onChangeConditions: PropTypes.func,
};

CatalogsList.defaultProps = {
  onChangeConditions: () => {},
};

export default CatalogsList;
