import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FormControl } from "components/templates/index";
import { SimpleScroller } from "components/organism";
import { IconDrawer } from "components/molecules/index";
import { Badge, Button, Input } from "components/atoms/index";
import { useAttribute, useProduct } from "_hooks";
import { LOADING, SUCCESS } from "_constants/redux.constants";
import { PLUGIN } from "_constants/attribute.constants";

const AttributeLabel = ({ attributeKey, name, origin }) => {
  return (
    <div className="--attribute-label">
      <div>
        <Badge content={attributeKey} border="purple"></Badge>
        <p>{name}</p>
      </div>
      <div>
        <Badge content={origin} border="purple"></Badge>
      </div>
    </div>
  );
};

const ApproveData = ({attributes, productId, file, onCancel, onSave, prefix }) => {
  const { fetchAttributeToLayer, toLayerData: toLayer, toLayerStatus } = useAttribute();
  const { t } = useTranslation();
  
  const { putStatus } = useProduct();

  const [newData, setNewData] = useState({});

  useEffect(() => {
    fetchAttributeToLayer({
      w: {
        folderId: productId,
        attributes
      }
    });
  }, []);

  useEffect(() => {

  }, [newData]);

  return (
    <>
      <div className={`${prefix}__approve-data`}>

        {toLayerStatus === SUCCESS &&
          <div className="--image-data">
            <div>
              <img src={file.baseKeyS3Carousel} />
            </div>
            <div>
              <div>Valores de atributos recuperados</div>
              <div>
                <SimpleScroller isHorizontal={false}>
                  {toLayer?.map((a, index) => 
                    <FormControl
                      content={
                        <Input
                          name={a?.attribute}
                          value={newData?.[a?.attribute] ? newData[a.attribute] : a?.value }
                          onChange={({ target: { value }}) => setNewData((d) => {
                            let aux = {...d};
                            aux[a?.attribute] = value;
                            return aux;
                          })}
                          size="md"
                        />
                      }
                      key={index}
                      labelProps={{
                        content: 
                          <AttributeLabel
                            attributeKey={a?.attribute}
                            name={a?.name}
                            origin={a?.origin?.type === PLUGIN ? a?.origin?.source : "XLS"}
                          />
                        ,
                        size: "md"
                      }}
                      orientation="vertical"
                    />
                  )}
                </SimpleScroller>
              </div>
            </div>
          </div>
        }
        
      </div>
      <div className={`${prefix}__buttons`}>
        <Button
          content={t("common:form.cancel")}
          onClick={() => onCancel()}
          type="underline"
        />
        <Button
          content={"Guardar y generar"}
          disabled={putStatus === LOADING}
          onClick={() => onSave(newData)}
          sm
          type="purple"
        />
      </div>
    </>
  );
};

export default ApproveData;

