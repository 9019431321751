
import Slider from 'react-rangeslider'
import { Switch } from "components/atoms";
import { FormInput } from "components/molecules/Form";
import { ButtonColor } from "components/molecules";
import { FormControl } from "components/templates";
import { Accordion } from "components/organism";
import useNbskEditor from "_hooks/useNbskEditor";
import {
  STYLE_AUTOCOLOR,
  STYLE_BORDER,
  STYLE_BORDERCOLOR,
  STYLE_BORDERWIDTH,
  STYLE_OPACITY,
  STYLE_SHADOW,
  STYLE_SHADOWBLUR,
  STYLE_SHADOWOFFSETX,
  STYLE_SHADOWOFFSETY,
  STYLE_SHAPECOLOR
} from "_constants/nbskEditor.constants";
import 'react-rangeslider/lib/index.css'

const StyleSection = ({ canvas, selection, onChange, prefix, values }) => {
  
  const { isVisible } = useNbskEditor(canvas);

  return(
    <Accordion
      title={"Estilo de forma"}
      headerRight={null}
      isOpen={true}
      content={
        <div className={`${prefix}__form-styles`}>

          {isVisible(selection, STYLE_AUTOCOLOR) &&
            <FormControl
              className="--color-auto"
              content={
                <Switch
                  label=" "
                  checked={values?.[STYLE_AUTOCOLOR] ?? false}
                  onChange={(value) => onChange({ name: STYLE_AUTOCOLOR, value })}
                />
              }
              labelProps={{ content: "Color auto", size:"sm"}}
              orientation="vertical"
            />
          }

          {isVisible(selection, "style:shapeColor") &&
            <FormControl
              className="--shape-color"
              content={
                <ButtonColor
                  disabled={!!values?.[STYLE_AUTOCOLOR]}
                  onChange={({ hex }) => onChange({ name: STYLE_SHAPECOLOR, value: hex })}
                  placement="top"
                  value={values?.[STYLE_SHAPECOLOR]}
                />
              }
              labelProps={{ content: "Color de forma",  size:"sm"}}
              orientation="vertical"
            />
          }

          {isVisible(selection, STYLE_OPACITY) &&
            <FormControl
              className="--opacity"
              content={
                <Slider
                  format={(v) => `${(v * 100).toFixed(0)}%`}
                  orientation="horizontal"
                  min={0}
                  max={1}
                  name={STYLE_OPACITY}
                  onChange={(v) => onChange({ name: STYLE_OPACITY, value: v})}
                  onChangeComplete={console.log}
                  step={0.1}
                  value={values?.[STYLE_OPACITY]}
                />  
              }
              labelProps={{ content: "Opacidad", size:"sm" }}
              orientation="vertical"
            />
            // <FormInput
            //   className="--opacity"
            //   label="Opacidad"
            //   name={STYLE_OPACITY}
            //   onChange={({ target: { name, value }}) => onChange({ name, value })}
            //   orientation="vertical"
            //   size="sm"
            //   value={values?.[STYLE_OPACITY]}
            // />
          }

          {isVisible(selection, STYLE_BORDER) &&
            <FormControl
              className="--border"
              content={
                <Switch
                  label=""
                  checked={values?.[STYLE_BORDER]}
                  onChange={(value) => onChange({ name: STYLE_BORDER, value })}
                />
              }
              labelProps={{ content: "Borde", size:"sm"}}
              orientation="vertical"
            />
          }

          {isVisible(selection, STYLE_BORDERCOLOR) &&
            <FormControl
              className="--border-color"
              content={
                <ButtonColor
                  disabled={!!!values?.[STYLE_BORDER]}
                  onChange={({ hex }) => onChange({ name: STYLE_BORDERCOLOR, value: hex })}
                  placement="top"
                  value={values?.[STYLE_BORDERCOLOR] ?? "#ffffff"}
                />
              }
              labelProps={{ content: "Color de borde",  size:"sm"}}
              orientation="vertical"
            />
          }

          {isVisible(selection, STYLE_BORDERWIDTH) &&
            <FormInput
              className="--border-width"
              disabled={!!!values?.[STYLE_BORDER]}
              label="Grosor"
              name={STYLE_BORDERWIDTH}
              onChange={({target: { name, value }}) => onChange({ name, value })}
              orientation="vertical"
              size="sm"
              value={values?.[STYLE_BORDERWIDTH]}
            />
          }

          {isVisible(selection, STYLE_SHADOW) &&
            <FormControl
              className="--shadow"
              content={
                <Switch
                  label=""
                  checked={!!values?.[STYLE_SHADOW]}
                  onChange={(value) => onChange({ name: STYLE_SHADOW, value })}
                />
              }
              labelProps={{ content: "Sombra", size:"sm"}}
              orientation="vertical"
            />
          }

          {isVisible(selection, STYLE_SHADOWOFFSETX) &&
            <FormInput
              className="--shadow-offset"
              disabled={!values?.[STYLE_SHADOW]}
              label="Offset X"
              name={STYLE_SHADOWOFFSETX}
              onChange={({target: { name, value }}) => onChange({ name, value })}
              orientation="vertical"
              size="sm"
              value={values?.[STYLE_SHADOWOFFSETX]}
            />
          }

          {isVisible(selection, STYLE_SHADOWOFFSETY) &&
            <FormInput
              className="--shadow-offset"
              disabled={!values?.[STYLE_SHADOW]}
              label="Offset Y"
              name={STYLE_SHADOWOFFSETY}
              onChange={({target: { name, value }}) => onChange({ name, value })}
              orientation="vertical"
              size="sm"
              value={values?.[STYLE_SHADOWOFFSETY]}
            />
          }

          {isVisible(selection, STYLE_SHADOWBLUR) &&
            <FormInput
              className="--shadow-blur"
              disabled={!values?.[STYLE_SHADOW]}
              label="Difuminado"
              name={STYLE_SHADOWBLUR}
              onChange={({target: { name, value }}) => onChange({ name, value})}
              orientation="vertical"
              size="sm"
              value={values?.[STYLE_SHADOWBLUR]}
            />
          }
        </div>
    } />
  );
};

export default StyleSection;
