import i18n from "_config/lang/i18n";
import {
  ACTIVE,
  APPROVED,
  reduxActions as PRODUCT,
  REQUIRED,
  TOREVIEW,
  TYPE_FILES,
} from "_constants/product.constants";
import capitalize from "_utils/string/capitalize";

/**
 *
 * @param {Object | FormData} data
 * @param {Array} types
 * @param {Object} extras
 * @returns
 */
export const post =
  (data, types = null, extras = {}) =>
  (dispatch) => {
    if (!types) {
      types = [PRODUCT.POST_STARTED, PRODUCT.POST_SUCCESS, PRODUCT.POST_FAIL];
    }

    return dispatch({
      NBSK: {
        endpoint: "/folder/upload",
        types,
        reqType: "POST",
        ...extras,
        data,
      },
    });
  };

/**
 * 
 * @param { Object } params 
 * @param { Object } extras 
 * @returns 
 */
export const get = (params, extras = {}) => (dispatch) => {
  if (!extras?.types) {
    extras.types = [
      PRODUCT.GET_STARTED,
      PRODUCT.GET_SUCCESS,
      PRODUCT.GET_FAIL
    ];
  }
  
  return dispatch({
    NBSK: {
      endpoint: "/folder",
      reqType: "GET",
      ...extras,
      params
    },
  });
};

/**
 *
 * @param {Object} params
 * @param {Array} types
 * @param {boolean | object} extend
 * @returns
 */
export const find = (params, extras = {}) => (dispatch) => {
    if (typeof extras !== "object") extras = {};
    if (!extras?.types) {
      extras.types = [
        PRODUCT.ALL_STARTED,
        PRODUCT.ALL_SUCCESS,
        PRODUCT.ALL_FAIL,
      ];
    }
    
    return dispatch({
      NBSK: {
        endpoint: "/folder",
        feedback: {
          success: false,
        },
        ...extras,
        params: {
          a: params?.a,
          f: params?.f ?? ["data", "key", "process", "filesId"],
          w: {
            status: ACTIVE,
            ...(params?.w ?? {}),
            type: TYPE_FILES,
          },
          opt: { ...(params?.opt ?? undefined) },
        },
      },
    });
  };

/**
 *
 * @param {Object} params
 * @returns
 */
export const findOne = (params, extras = {}) => {
  if (!extras?.types) {
    extras.types = [PRODUCT.ONE_STARTED, PRODUCT.ONE_SUCCESS, PRODUCT.ONE_FAIL];
  }
  return find(
    params,
    extras
  );
};

/**
 *
 * @param {object} query
 * @param {boolean} partial
 * @returns
 */
export const count = (
  { query, partial = false, count = { _: "@" } },
  extras = {}
) => {
  let types;
  if (!extras?.types) {
    if (partial) {
      types = [
        PRODUCT.COUNTPIECE_STARTED,
        PRODUCT.COUNTPIECE_SUCCESS,
        PRODUCT.COUNTPIECE_FAIL,
      ];
    } else {
      types = [
        PRODUCT.COUNT_STARTED,
        PRODUCT.COUNT_SUCCESS,
        PRODUCT.COUNT_FAIL,
      ];
    }
  }

  let fields = Object.keys(count).map((key) => count[key]);
  let params = {
    a: {
      count,
    },
    f: fields,
    w: {
      status: ACTIVE,
      ...query,
    },
  };

  return find(params, {
    ...extras,
    types: extras?.types ?? types,
  });
};

export const countByField =
  ({ query, countField = { _: "@" } }) =>
  (dispatch) => {
    return dispatch(
      count(
        { query, partial: true, count: countField },
        {
          types: [
            PRODUCT.COUNT_BY_FIELD_STARTED,
            PRODUCT.COUNT_BY_FIELD_SUCCESS,
            PRODUCT.COUNT_BY_FIELD_FAIL,
          ],
        }
      )
    );
  };

/**
 *
 * @param {array} ids
 * @param {Object} data
 * @param {array} types
 * @param {object} extras
 * @returns
 */
export const put = (ids, data, types = null, extras = {}) =>
  (dispatch) => {
    if (!Array.isArray(ids)) ids = [ids];
    if (!types) {
      types = [PRODUCT.PUT_STARTED, PRODUCT.PUT_SUCCESS, PRODUCT.PUT_FAIL];
    }

    return dispatch({
      NBSK: {
        types: types,
        endpoint: `/folder`,
        reqType: "PUT",
        ...extras,
        data: {
          c: [data],
          w: ids,
        },
      },
    });
  };

/**
 * 
 * @param {String} id 
 * @param {String} status 
 * @param {Object} extras 
 * @returns 
 */
export const putCopyStatus = (id, status, extras = {}) => (dispatch) => {
  let data = {
    process: {
     reviewCopy: status,
    },
  };

  return dispatch(
    put(id, data, null, {
      endpoint: "/folder/change/status",
      ...extras
    })
  );
};

/**
 *
 * @param {string | array} id
 * @returns
 */
export const approveCopy = (id) => (dispatch) => {
  return dispatch(
    putCopyStatus(id, APPROVED, {
      feedback: {
        success: {
          area: "products:details.info.feedback.approve.area",
          info: "products:details.info.feedback.approve.success",
        },
        error: {
          area: "products:details.info.feedback.approve.area",
          info: "products:details.info.feedback.approve.success",
        },
      },
    })
  );
};

/**
 *
 * @param {string | array} id
 * @returns
 */
export const approvePhotos = (id, noFeedback) => (dispatch) => {
  let data = {
    process: {
      reviewMaster: APPROVED,
    },
  };
  let feedback = {
    success: {
      area: "products:details.imagery.feedback.approve.area",
      info: "products:details.imagery.feedback.approve.success",
    },
    error: {
      area: "products:details.imagery.feedback.approve.area",
      info: "products:details.imagery.feedback.approve.success",
    },
  };
  if (noFeedback) feedback = { success: false, error: false };
  return dispatch(
    put(id, data, null, {
      endpoint: "/folder/change/status",
      feedback,
    })
  );
};

/**
 *
 * @param {string | array} id
 * @returns
 */
export const disapprovePhotos = (id) => (dispatch) => {
  let data = {
    process: {
      reviewMaster: REQUIRED
    }
  };

  return dispatch(
    put(id, data, null, {
      endpoint: "/folder/change/status",
      feedback: {
        success: false,
        error: false,
      },
    })
  );
};

/**
 *
 * @param {string | array} id
 * @returns
 */
export const toReviewPhotos = (id) => (dispatch) => {
  let data = {
    process: {
      reviewMaster: TOREVIEW,
    },
  };

  return dispatch(
    put(id, data, null, {
      endpoint: "/folder/change/status",
      feedback: {
        success: false,
        error: false,
      },
    })
  );
};

/**
 * @param {String} key
 * @param {Object} data
 * @param {Object} extras
 * @returns
 */
export const putCopy =
  (keys, data, extras = {}) =>
  (dispatch) =>
    dispatch(
      put(
        keys,
        { data },
        [
          PRODUCT.PUT_COPY_STARTED,
          PRODUCT.PUT_COPY_SUCCESS,
          PRODUCT.PUT_COPY_FAIL,
        ],
        {
          feedback: {
            success: {
              area: "products:details.info.feedback.update.area",
              info: "products:details.info.feedback.update.success",
            },
            error: {
              area: "products:details.info.feedback.update.area",
              info: "products:details.info.feedback.update.success",
            },
          },
          ...extras,
        }
      )
    );

/**
 *
 * @param {string} key
 * @param {Object} data
 * @returns
 */
export const addAdaptation = (key, data, success) => (dispatch) =>
  dispatch(
    putCopy(key, data, {
      feedback: {
        success,
        error: true,
      },
    })
  );

/**
 *
 * @param {Array} files
 * @param {Object} params
 * @returns
 */
export const upload = (files, params = {}, feedback = {}) =>
  (dispatch) => {
    let data = new FormData();
    for (let i in files) {
      data.append("files", files[i]);
    }
   
    for (let i in params) {
      if (typeof params[i] === "object") {
        data.append(i, JSON.stringify(params[i]));
      } else {
        data.append(i, params[i]);
      }
    }

    return dispatch(
      post(data, null, {
        endpoint: "/folder/upload",
        feedback,
        headers: { "Content-Type": "multipart/form-data" },
      })
    );
  };

/**
 *
 * @returns
 */
export const updateImages = () => (dispatch, getState) =>
  dispatch({
    type: PRODUCT.UPDATE_FILES,
    payload: {
      data: getState()?.file.post?.data ?? [],
    },
  });

/**
 * 
 * @param {*} data 
 * @returns 
 */
export const postOne = ({ data }, extras = {}) => async (dispatch, getState) => {
  const {
    app: {
      columnKey: { data: name },
    },
  } = getState();

  let response = await dispatch({
    NBSK: {
      types: [PRODUCT.POST_STARTED, PRODUCT.POST_SUCCESS, PRODUCT.POST_FAIL],
      endpoint: "/folder",
      reqType: "POST",
      feedback: {
        success: {
          area: `${i18n.t(
            "products:newSku.feedback.new_sku.area"
          )} ${capitalize(name)}`,
          info: ` ${capitalize(name)} ${data[name]} ${i18n.t(
            "products:newSku.feedback.new_sku.success"
          )}`,
        },
        error: true,
        ...extras
      },
      data: {
        c: [{ data }],
      },
    },
  });

  return response;
};

/**
 * 
 * @param {*} w 
 * @returns 
 */
export const fetchAllList = ({ w }, extras = {}) => (dispatch) =>
  dispatch(
    find({
      f: ["_id"],
      w: {
        status: ACTIVE,
        ...w,
      },
    },
    {
      types: [
        PRODUCT.ALL_LIST_STARTED,
        PRODUCT.ALL_LIST_SUCCESS,
        PRODUCT.ALL_LIST_FAIL,
      ],
      ...extras
    })
  );

/**
 *
 * @param {Array} ids
 * @param {Object} w
 * @returns
 */
export const downloadXls = (ids, w, flags, extras = {}) => (dispatch) => {
  let data = {
    f: [],
  };
  if (ids?.length) {
    data.w = { _id: ids };
  } else {
    data.w = w;
  }
  data.$config = flags;
  return dispatch(
    post(
      data,
      [
        PRODUCT.DOWNLOAD_XLS_STARTED,
        PRODUCT.DOWNLOAD_XLS_SUCCESS,
        PRODUCT.DOWNLOAD_XLS_FAIL,
      ],
      {
        endpoint: "/folder/download",
        reqType: "POST",
        feedback: {
          success: false,
          error: true,
        },
        ...extras,
      }
    )
  );
};

export const setCounters =
  ({ count, countPiece }) =>
  (dispatch) => {
    return dispatch({
      type: PRODUCT.SET_COUNTERS,
      payload: {
        count,
        countPiece: Number.isInteger(countPiece) ? countPiece : count,
      },
    });
  };

/**
 *
 * @param {array} keys
 * @param {number} minFilesId
 * @param {array} types
 * @param {object} extras
 * @returns
 */
export const putAnalyze =
  (keys, minFilesId, types = null, extras = {}) =>
  (dispatch) => {
    if (!Array.isArray(keys)) keys = [keys];
    if (!types) {
      types = [
        PRODUCT.ANALYZE_STARTED,
        PRODUCT.ANALYZE_SUCCESS,
        PRODUCT.ANALYZE_FAIL,
      ];
    }

    return dispatch({
      NBSK: {
        types: types,
        endpoint: `/folder/change/status`,
        reqType: "PUT",
        ...extras,
        data: {
          $action: "analyze",
          $config: {
            minFilesId,
          },
          w: keys,
        },
      },
    });
  };

/**
 * 
 * @param { Object } params 
 * @param { Object } extras 
 * @returns 
 */
export const filterAnalizeId = (params, extras = {}) => (dispatch) => {
  if (!extras?.types) {
    extras.types = [
      PRODUCT.ANALYZE_ID_STARTED,
      PRODUCT.ANALYZE_ID_SUCCESS,
      PRODUCT.ANALYZE_ID_FAIL
    ];
  }
  return dispatch(find(params, extras));
};

/**
 * @param { Object } ids 
 * @param { Object } extras 
 * @returns 
 */
export const remove = (ids, extras = {}) => (dispatch) => {
  if (!extras?.types) {
    extras.types = [
      PRODUCT.REMOVE_STARTED,
      PRODUCT.REMOVE_SUCCESS,
      PRODUCT.REMOVE_FAIL,
    ];
  }

  return dispatch({
    NBSK: {
      endpoint: `/folder`,
      reqType: "DELETE",
      ...extras,
      data: {
        w: Array.isArray(ids) ? ids : [ids]
      },
      ...extras
    },
  });
};

export const pairCount = () => (dispatch) => 
  dispatch({ type: PRODUCT.COUNTPIECE_PAIR });

// Reset functions
export const resetAll = () => (dispatch) =>
  dispatch({ type: PRODUCT.ALL_RESET });

export const resetAllList = () => (dispatch) =>
  dispatch({ type: PRODUCT.ALL_LIST_RESET });

export const resetCount = () => (dispatch) =>
  dispatch({ type: PRODUCT.COUNT_RESET });

export const resetDetails = () => (dispatch) =>
  dispatch({ type: PRODUCT.DETAILS_RESET });

export const resetDownnloadXls = () => (dispatch) =>
  dispatch({ type: PRODUCT.DOWNLOAD_XLS_RESET });
 
export const resetOne = () => (dispatch) =>
  dispatch({ type: PRODUCT.ONE_RESET });

export const resetPost = () => (dispatch) =>
  dispatch({ type: PRODUCT.POST_RESET });

export const resetPut = () => (dispatch) =>
  dispatch({ type: PRODUCT.PUT_RESET });

export const resetAnalyze = () => (dispatch) =>
  dispatch({ type: PRODUCT.ANALYZE_RESET });

export const resetRemove = () => (dispatch) =>
  dispatch({ type: PRODUCT.REMOVE_RESET});

export const resetAllProps = () => (dispatch) => {
  dispatch(resetAll());
  dispatch(resetCount());
  dispatch(resetOne());
  dispatch(resetAllList());
};
export const resetAllPropsCatalog = () => (dispatch) => {
  dispatch(resetOne());
};

export const resetAllPropsDetails = () => (dispatch) => {
  dispatch(resetAll());
  dispatch(resetDetails());
  dispatch(resetAllList());
};

export const refreshProduct = (id, data, refresh) => (dispatch) => {
  switch (refresh) {
    case "productAll":
      return dispatch({ type: PRODUCT.ALL_REFRESH, payload: [{
        _id: id,
        c: data
      }]});
      
    case "productOne":
      return dispatch({ type: PRODUCT.ONE_REFRESH, payload: {
        _id: id,
        c: data
      }});

    case "productDetails":
      return dispatch({ type: PRODUCT.DETAILS_REFRESH, payload: {
        _id: id,
        c: data
      }});

    default: 
      return;
  }
};

const actions = {
  addAdaptation,
  count,
  find,
  findOne,
  put,
  putCopy,
  upload,
  updateImages,
  resetAll,
  resetAllList,
  resetCount,
  resetOne,
  resetAllProps
};

export default actions;
