import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { useAppSettings } from "_hooks";
import { Button, Hr, NubeskIcons } from "components/atoms";
import { InputLabel, Select } from "components/molecules/Inputs";
import InputTest from "components/molecules/Inputs/InputTest/InputTest";
import { SimpleScroller, UploadDrop } from "../index";
import { closePanelLeft, openModal, toggleRefresh } from "_redux/ui/ui.actions";
import { postOne } from "_redux/product/product.actions";
import { find as fetchAdaptations } from "_redux/adaptation/adaptation.actions";
import { uploadImages } from "_redux/file/file.actions";
import { selectAdaptationAll } from "_redux/adaptation/adaptation.selector";
import { capitalize } from "_utils/string";
import { sortImageFiles } from "_utils/upload";

const orders = {
  name: 1,
  category: 2,
  brand: 2
};

const prefix = "o-new-sku-panel";

const sizes = {
  simple_description: 2,
  marketing_description: 2
};

const NewSkuPanel = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const adaptations = useSelector(selectAdaptationAll);
  const { appColumnKey, appColumnKeyProps, appPropertyColumns } = useAppSettings();

  const classForComponent = classNames(prefix);
  const [fullObject, setFullObject] = useState(null);
  const [adaptationOptions, setAdaptationsOptions] = useState([]);
  const [enable, setEnable] = useState(false);
  const [master, setMaster] = useState(null);
  const [formData, setFormData] = useState({});
  const [files, setFiles] = useState([]);

  const handleChange = ({ target: { name, value } }) =>
    setFormData({ ...formData, [name]: value });

  const handleSubmit = async () => {
    setEnable(false);
    const response = await dispatch(postOne({ data: formData }));

    if (response?._c?.success) {
      let { _id } = response?.data?.[0];
      if (files.length) {
        let adaptationId;
        for (let a of adaptations) {
          if (a.name === master) {
            adaptationId = a._id;
            break;
          }
        }
        await submitFiles(adaptationId, _id);
      }
      setFormData({});
      setFiles([]);
      setMaster(null);
      dispatch(toggleRefresh("products"));
      dispatch(closePanelLeft());
    }
    setEnable(true);
  };

  const submitFiles = (adaptationId, folderId) =>
    dispatch(uploadImages(files, { adaptationId, folderId }));

  const onChangeFilesHandler = (data) => {
    data = sortImageFiles(data);
    setFiles(data);
  };

  useEffect(() => {
    dispatch(fetchAdaptations());
  }, []);

  useEffect(() => {
    if (adaptations) {
      setAdaptationsOptions([adaptations?.[0].name]);
    }
  }, [adaptations]);
  
  useEffect(() => {
    if (!appPropertyColumns) return;
    let _fullObject = [];
    for (let field of appPropertyColumns) {
      let { class: { enum: allowed }, key, flag, name } = field;

      if (flag?.key) continue;
      _fullObject.push({
        name: key,
        array: allowed || null,
        original: capitalize(name) || key,
        required: !!flag?.require
      });
    }

    _fullObject.sort((a, b) => {
      if (a.required && !b.required) return -1;
      if (!a.required && b.required) return 1;

      return 0;
    });
    setFullObject(_fullObject);
  }, [appPropertyColumns]);

  useEffect(() => {
    if (!formData[appColumnKey]) return setEnable(false);
    for (let key in appPropertyColumns) {
      if (appPropertyColumns[key].require && !formData[key]) {
        return setEnable(false);
      }
    }
    setEnable(true);
  }, [formData]);

  return (
    <div className={classForComponent}>
      <div className={`${prefix}__header`}>
        <NubeskIcons icon="plus" size={20} />{" "}
        <span className={`${prefix}__content-title`}>
          {t("products:newSku.new", { key: appColumnKeyProps?.name?.toUpperCase() })}
        </span>
      </div>
      
      <div className={`${prefix}__button-upload`}>
        <Button
          content={t("products:newSku.upload_bulk")}
          type="underline"
          style={{ fontSize: "1.8rem", padding: "1rem 3rem" }}
          onClick={() =>
            dispatch(
              openModal({
                typeModal: "upload",
                header: { text: t("products:newSku.upload_bulk") },
              })
            )
          }
        />
        <Hr 
          className={`${prefix}__or`}
          content={t("common:form.or")} 
        />
      </div>
      <div className={`${prefix}__content`}>
        <SimpleScroller disableTracksWidthCompensation>
          <div className={`${prefix}__content-inputs`}>
            <InputLabel label={appColumnKeyProps?.name} required={t("common:form.required")}>
              <InputTest
                type="black"
                placeholder={`00000-0000`}
                onChange={handleChange}
                name={appColumnKey}
                value={formData[appColumnKey] || ""}
              />
            </InputLabel>

            {fullObject && fullObject?.map(({ name, original, array, required }, index) => {
              if (!array) {
                return (
                  <div
                    key={index}
                    style={{
                      order: orders[name] || 2,
                      gridColumn: `span ${sizes[name] || 1}`,
                    }}
                  >
                    <InputLabel 
                      label={original} 
                      required={required ? t("common:form.required") : ""}
                    >
                      <InputTest
                        type="black"
                        onChange={handleChange}
                        name={name}
                        value={formData[name] || ""}
                      />
                    </InputLabel>
                  </div>
                );
              }
                return (
                  <div
                    key={index}
                    style={{ order: orders[name] || 2, gridColumn: "span 1" }}
                  >
                    <InputLabel
                      label={original} 
                      required={required ? t("common:form.required") : ""}
                    >
                      <Select
                        type="black"
                        onChange={handleChange}
                        options={array}
                        value={formData[name] || `${original}`}
                        name={name}
                      />
                    </InputLabel>
                  </div>
                );
            })}

            <div style={{ order: 4 }}>
              <InputLabel
                label={`Add ${appColumnKeyProps?.name} photos`}
                required=""
              >
                <Select
                  type="black"
                  options={adaptationOptions}
                  value={master || "Select master"}
                  onChange={({ target }) => setMaster(target.value)}
                />
              </InputLabel>
            </div>
            {master && !files.length ? (
              <div className={`${prefix}__drop`}>
                <UploadDrop
                  file={{ data: {}, error: false }}
                  setFile={onChangeFilesHandler}
                  label={"products:newSku.selectImages"}
                />
                <Button
                  className={`${prefix}__drop-btn`}
                  sqr
                  sm
                  type={"color"}
                  content={<NubeskIcons icon="times" />}
                  onClick={() => setMaster(null)}
                />
              </div>
            ) : null}

            {files.length ? (
              <div className={`${prefix}__thumb-container`}>
                {files.map((_file, index) => {
                  return (
                    <div className={`${prefix}__thumb`} key={_file.path}>
                      <img
                        src={URL.createObjectURL(_file)}
                        onLoad={() => {
                          URL.revokeObjectURL(_file);
                        }}
                      />
                      <Button
                        content={<NubeskIcons icon="times" />}
                        type={"color"}
                        sm
                        sqr
                        onClick={() =>
                          setFiles([...files.filter((item, i) => i !== index)])
                        }
                      />
                    </div>
                  );
                })}
              </div>
            ) : null}
          </div>
        </SimpleScroller>
      </div>{" "}
      <div className={`${prefix}__footer`}>
        <Button
          type="underline"
          content={t("products:newSku.cancel")}
          onClick={() => dispatch(closePanelLeft())}
        />
        <Button
          type="purple"
          content={`${t("products:newSku.add")} ${appColumnKeyProps?.name?.toUpperCase()}`}
          disabled={!enable}
          sm
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
};

export default NewSkuPanel;
